import * as React from 'react';
import styled from 'styled-components';
import PageData from '../components/ridge/content/ios.yaml';
import { PageContainer } from '../components/ridge/global/Wrappers';
import {
  Hero,
  ThreeColumnIconCards,
  Work,
  Benefits,
  Reviews,
  Resources,
} from '../components/ridge/sections';
import DarkFooter from '../components/segments/DarkFooter';
import LetsConnectSection from '../components/segments/LetsConnectSection';
import { NavBar } from '../components/segments/NavBar';
import { IndexLayout } from '../layouts';

// markup
const ios = () => {
  React.useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);
  return (
    <IndexLayout>
      <PageContainer>
        <NavBar invert alwaysOpaque />
        <Hero
          heading={PageData.hero.heading}
          body={PageData.hero.body}
          formHeading={PageData.hero.form.heading}
        />
        <ThreeColumnIconCards
          heading={PageData.services.heading}
          body={PageData.services.body}
          items={PageData.services.iconCards}
          link={PageData.services.link}
        />
        <Work
          heading={PageData.relatedWork.heading}
          body={PageData.relatedWork.body}
          items={PageData.relatedWork.items}
        />
        <Benefits leftCol={PageData.benefits.leftCol} rightCol={PageData.benefits.rightCol} />
        <Reviews heading={PageData.reviews.heading} reviews={PageData.reviews.reviews} />
        <LetsConnectSection />
        <Resources
          heading={PageData.resources.heading}
          body={PageData.resources.body}
          link={PageData.resources.link}
          items={PageData.resources.items}
        />
        <DarkFooter />
      </PageContainer>
    </IndexLayout>
  );
};

export default ios;
